import React, { useState } from 'react';

const Gallery = () => {
  const [videos] = useState([
    { url: "https://www.youtube.com/embed/majnoer4i6o" }
    
  ]);

  return (
    <main>
      <div className="gallery">
        <ul>
          {videos.length === 0 ? (
            <li>
              <p>Here will be a list of saved videos.</p>
              <p>This video is an example. It was made with the help of a drone.</p>
              <iframe 
                title="Your iframe title" 
                style={{ width: '100%' }} 
                src="https://www.youtube.com/embed/majnoer4i6o" 
                frameBorder="0" 
                allowFullScreen
              ></iframe>
            </li>
          ) : (
            videos.map((video, index) => (
              <li key={index}>
                <iframe 
                  title={`Video ${index + 1}`} 
                  style={{ width: '100%' }} 
                  src={video.url} 
                  frameBorder="0" 
                  allowFullScreen
                ></iframe>
              </li>
            ))
          )}
        </ul>
      </div>
    </main>
  );
}

export default Gallery;

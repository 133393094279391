import React, { useState } from 'react';
import axios from 'axios';

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/users/register', formData); // Змінено шлях до маршруту створення користувача
      console.log('User created:', response.data);
      // Додайте код для обробки успішної реєстрації, наприклад, перенаправлення або повідомлення
      alert('Registration successful!');
    } catch (error) {
      console.error('Error creating user:', error);
      // Додайте код для обробки помилки реєстрації, наприклад, повідомлення користувачеві
      alert('Error registering user. Please try again.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className='registr-form'>
      <h2>Registration Form</h2>
      
      <form onSubmit={handleSubmit}>
        <fieldset>
          <label htmlFor="firstName">Enter Your First Name: 
            <input id="firstName" name="firstName" type="text" value={formData.firstName} onChange={handleChange} required />
          </label>
          <label htmlFor="lastName">Enter Your Last Name: 
            <input id="lastName" name="lastName" type="text" value={formData.lastName} onChange={handleChange} required />
          </label>
          <label htmlFor="email">Enter Your Email: 
            <input id="email" name="email" type="email" value={formData.email} onChange={handleChange} required />
          </label>
        </fieldset>
        <button type="submit">Register</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default RegistrationForm;
